import { App, DataCategories, DataField, DiagnosticLevel, makeBooleanDataField, makeInt64DataField, makeStringDataField, Session, SuppressNexus, TelemetryLogger, User } from '@microsoft/oteljs';
import { OneDSEndpoint, OneDSSink } from '@microsoft/oteljs-1ds';
import { OfficeIdentity } from '@1js/office-start-auth-types';
import { getDigest } from '@1js/office-start-digest-utility';
import { encode } from '@1js/office-start-encoding-utility';
import { OfficeEnvironment } from '@1js/office-start-officehome-types';
import { ActionResult, ActionTarget, ActionType, ACTION_DETAILS, ACTION_RESULT, ACTION_TARGET, ACTION_TYPE, APP_WORKLOAD, APP_WORKLOAD_TYPE, Area, AREA, AUDIENCE_GROUP, BROWSER_SESSION_ID, DataSecurityLevel as OtelDataSecurityLevel, DEPLOYMENT_ENV, DeviceType, DEVICE_TYPE, DiagnosticProperties, DIAGNOSTIC_DETAILS, DIAGNOSTIC_TABLE_NAME, DOMAIN_ORIGIN, ErrorProperties, ERROR_CATEGORY, ERROR_COUNT, ERROR_DETAILS, ERROR_TYPE, EventCategoryConfig, EVENT_CATEGORY, EVENT_NAME, EVENT_SCHEMA_VERSION, FIELD_NAME_SEPARATOR, FLIGHTS, FLIGHTS_HASH, ID, IS_INTENTIONAL, PageName, PAGE_NAME, PAGE_TYPE, PAGE_VIEW_DETAILS, RELEASE, ReleaseAudienceGroup, REQUEST_ORIGIN, SCHEMA_VERSION, SERVER_LOCATION, SESSION_DETAILS, SubArea, SUB_AREA, TelemetryEvent, TelemetryProperties, UserType } from '@1js/office-start-telemetry-types';
import { filterPii, getFlattenedFieldName } from '@1js/office-start-telemetry-utility';

export interface OtelContext {
    appName: string;
    appPlatform: string;
    appWorkload: string;
    appWorkloadType: string;
    bundleBuildDate: string;
    bundleBuildId: string;
    correlationId: string;
    deploymentEnvironment: string;
    domainOrigin: string;
    eventCategoryConfig: EventCategoryConfig;
    flights: string;
    isCorpNet: boolean;
    isTestTraffic: boolean;
    oTelEnabled: boolean;
    oTelAriaTenant: string;
    oTelAriaNameSpace: string;
    sessionId: string;
    serverLocation: string;
    requestOrigin: string;
    initialPageName?: string; // TODO: Remove it after missing PageView event issue fixed
    initialPageType?: string; // TODO: Remove it after missing PageView event issue fixed
}

export interface OtelAuthContext extends OtelContext {
    identity: OfficeIdentity;
}

export interface PageViewProperties {
    readonly eventName?: string;
    readonly isIntentional: boolean;
    readonly pageName: PageName;
    readonly pageType: string;
    readonly securityThreshold?: DataSecurityLevel;
}

//TODO: change to extends ActionProperties after telemetry-type updated
export interface UnAuthActionProperties {
    readonly id: string;
    readonly area: Area;
    readonly result: ActionResult;
    readonly target: ActionTarget;
    readonly type?: ActionType;
    readonly subArea?: SubArea;
    readonly targetId?: string;
    readonly pageName: PageName;
    readonly pageType: string;
    readonly securityThreshold?: DataSecurityLevel;
}

export interface DeviceInfo {
      /**
     * @arg keyword: The word being looked for in a user agent string to determine the platform type.
     */
       keyword: string;
       deviceType: DeviceType;
}

export function getDeviceType(): DeviceType {
    const platforms: Array<DeviceInfo> = [
        { keyword: 'Windows NT', deviceType: 'PC' },
        { keyword: 'Windows XP', deviceType: 'PC' },
        { keyword: 'Macintosh', deviceType: 'Mac' },
        { keyword: 'Windows Phone', deviceType: 'WindowsPhone' },
        { keyword: 'iPad', deviceType: 'iPad' },
        { keyword: 'iPhone', deviceType: 'iPhone' },
        { keyword: 'Android', deviceType: 'AndroidPhone' },
        { keyword: 'Linux', deviceType: 'Linux' },  // This should be below Android since "Linux" shows up in the Android UserAgent string as well
        { keyword: 'CrOS', deviceType: 'Chromebook' }
    ];

    let deviceInfo: DeviceInfo = { keyword: 'Other', deviceType: 'Other' };

    const loweredUserAgentString = window.navigator.userAgent.toLowerCase();
    platforms.some(value => {
        if (loweredUserAgentString.indexOf(value.keyword.toLowerCase()) !== -1) {
            deviceInfo = value;
            return true;
        }

        return false;
    });
    return deviceInfo.deviceType;
}

interface ResourceTiming {
    name: string;
    tSize: number;  // transfer size
    dSize: number;  // decoded body size.
}

export const enum EventCategory {
    Action = 'Action',
    Diagnostic = 'Diagnostic',
    Error = 'Error',
    Feature = 'Feature',
    Impression = 'Impression',
    PageView = 'PageView',
    Performance = 'Performance',
    Request = 'Request',
    Session = 'Session'
}

class StandaloneOtelLogger {
    private unAuthOtelLogger: TelemetryLogger;
    private authOtelLogger: TelemetryLogger;
    private nameSpace: string;
    private eventCategoryConfig: EventCategoryConfig;
    private flights: string;
    private unAuthLoggerInitialized = false;
    private authLoggerInitialized = false;
    private readonly unAuthQueue: Array<TelemetryEvent> = [];
    private readonly authQueue: Array<TelemetryEvent> = [];

    public initializeUnAuthOTel(context: OtelContext): Promise<void> {
        if ( !(context.oTelEnabled && context.oTelAriaTenant) || this.unAuthOtelLogger) {
            return;
        }

        this.nameSpace = context.oTelAriaNameSpace;
        this.eventCategoryConfig = context.eventCategoryConfig;
        this.unAuthOtelLogger = new TelemetryLogger();
        this.unAuthOtelLogger.setTenantToken(
            context.oTelAriaNameSpace,
            context.oTelAriaTenant,
            SuppressNexus
        );
        this.flights = context.flights;

        return this.getPartAFields(
            context.appName,
            context.appPlatform,
            context.appWorkload,
            context.bundleBuildDate,
            context.correlationId,
            context.deploymentEnvironment,
            context.domainOrigin,
            context.flights,
            context.isCorpNet,
            context.isTestTraffic,
            context.sessionId,
            context.serverLocation,
            context.requestOrigin
        ).then(persistentFields => {
            const unAuthOneDsSink = this.getOneDsSink(persistentFields, context.deploymentEnvironment);

            this.unAuthOtelLogger.addSink(unAuthOneDsSink);
            this.unAuthLoggerInitialized = true;
            this.drainQueuedEvents(this.unAuthQueue, true); // isAnonymous == true
            this.logSessionStart(true, context.initialPageName, context.initialPageType); // isAnonymous == true
        });
    }

    public initializeAuthOTel(context: OtelAuthContext): Promise<void> {
        if ( !(context.oTelEnabled && context.oTelAriaTenant) || this.authOtelLogger) {
            return Promise.resolve();
        }

        this.nameSpace = context.oTelAriaNameSpace;
        this.eventCategoryConfig = context.eventCategoryConfig;
        this.authOtelLogger = new TelemetryLogger();
        this.authOtelLogger.setTenantToken(
            context.oTelAriaNameSpace,
            context.oTelAriaTenant,
            SuppressNexus
        );
        this.flights = context.flights;

        return this.getPartAFields(
            context.appName,
            context.appPlatform,
            context.appWorkload,
            context.bundleBuildDate,
            context.correlationId,
            context.deploymentEnvironment,
            context.domainOrigin,
            context.flights,
            context.isCorpNet,
            context.isTestTraffic,
            context.sessionId,
            context.serverLocation,
            context.requestOrigin,
            context.identity
        ).then(persistentFields => {
            const authOneDsSink = this.getOneDsSink(persistentFields, context.deploymentEnvironment);

            this.authOtelLogger.addSink(authOneDsSink);
            this.authLoggerInitialized = true;
            this.drainQueuedEvents(this.authQueue, false); // isAnonymous == false
            this.logSessionStart(false); // isAnonymous == false
        });
    }

    public logSessionStart(isAnonymous: boolean, pageName?: string, pageType?: string, securityThreshold?: DataSecurityLevel): void {
        const params: TelemetryProperties = {};
        const eventSecurityLevel = securityThreshold ?? DataSecurityLevel.Security;
        const appSecurityLevel = this.getAppSecurityLevel();
        params[EVENT_CATEGORY] = 'Session';
        let eventName = undefined;

        if (isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }

            params[EVENT_NAME] = 'Session_Started';
            params[getFlattenedFieldName([SESSION_DETAILS, FLIGHTS])] = this.flights;
            params[getFlattenedFieldName([SESSION_DETAILS, PAGE_NAME])] = pageName ?? '';
            params[getFlattenedFieldName([SESSION_DETAILS, PAGE_TYPE])] = pageType ? this.getPageType(pageType) : '';
            eventName =  'Session';
            // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
            const fullyQualifiedName = [this.nameSpace, eventName].join('.');
            // Part C fields: Different for each event category
            //   - EventCategory
            //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
            const dataFields = this.convertToDataFields(params);

            this.sendTelemetryEvent(
                isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger,
                fullyQualifiedName,
                dataFields,
                DataCategories.ProductServiceUsage,
                DiagnosticLevel.RequiredServiceData
            );
        }
    }

    public logEvent(eventName: string, params: TelemetryProperties, securityThreshold: DataSecurityLevel, logger: TelemetryLogger): void {
        const eventSecurityLevel = securityThreshold ?? DataSecurityLevel.Security;
        const appSecurityLevel = this.getAppSecurityLevel();

        if ((logger) && this.isEligible(params)) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }
            const fullyQualifiedName = [this.nameSpace, eventName].join('.');
            const dataFields = this.convertToDataFields(params);

            this.sendTelemetryEvent(
                logger,
                fullyQualifiedName,
                dataFields,
                DataCategories.ProductServiceUsage,
                DiagnosticLevel.RequiredServiceData
            );
        }
    }

    public logPageView(properties: PageViewProperties, isAnonymous: boolean): void {
        const params: TelemetryProperties = {};
        const eventSecurityLevel = properties.securityThreshold ?? DataSecurityLevel.Security;
        const appSecurityLevel = this.getAppSecurityLevel();
        params[EVENT_CATEGORY] = 'PageView';
        let eventName = undefined;

        if (properties.pageName && (isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger) && this.eventCategoryConfig.pageViewEnabled) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }

            params[EVENT_NAME] = properties.eventName ?? '';
            params[getFlattenedFieldName([PAGE_VIEW_DETAILS, PAGE_NAME])] = properties.pageName;
            params[getFlattenedFieldName([PAGE_VIEW_DETAILS, PAGE_TYPE])] = this.getPageType(properties.pageType);

            params[getFlattenedFieldName([PAGE_VIEW_DETAILS, IS_INTENTIONAL])] = properties.isIntentional;

            eventName =  ['PageView', properties.pageName].join(FIELD_NAME_SEPARATOR);
            if (isAnonymous ? this.unAuthLoggerInitialized : this.authLoggerInitialized) {
                // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
                const fullyQualifiedName = [this.nameSpace, eventName].join('.');
                // Part C fields: Different for each event category
                //   - EventCategory
                //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
                const dataFields = this.convertToDataFields(params);

                this.sendTelemetryEvent(
                    isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger,
                    fullyQualifiedName,
                    dataFields,
                    DataCategories.ProductServiceUsage,
                    DiagnosticLevel.RequiredServiceData
                );
            } else {
                const event: TelemetryEvent = {
                    eventName,
                    params,
                    isDiagnostic: false,
                    securityThreshold: eventSecurityLevel as unknown as OtelDataSecurityLevel
                };
                isAnonymous
                    ? this.unAuthQueue.push(event)
                    : this.authQueue.push(event);
            }
        }
    }

    public logUnAuthUserAction(properties: UnAuthActionProperties): void {
        const appSecurityLevel = this.getAppSecurityLevel();
        const eventSecurityLevel = properties.securityThreshold ?? DataSecurityLevel.Security;
        if (this.eventCategoryConfig.actionEnabled) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }
            const params: TelemetryProperties = {};
            params[EVENT_CATEGORY] = 'Action';

            params[getFlattenedFieldName([ACTION_DETAILS, ACTION_TYPE])] =
            properties.type ?? 'Click';

            params[getFlattenedFieldName([ACTION_DETAILS, ID])] = properties.id;

            params[getFlattenedFieldName([ACTION_DETAILS, AREA])] = properties.area;

            params[getFlattenedFieldName([ACTION_DETAILS, SUB_AREA])] =
            properties.subArea ?? 'Other';

            params[getFlattenedFieldName([ACTION_DETAILS, ACTION_RESULT])] =
            properties.result;

            params[getFlattenedFieldName([ACTION_DETAILS, ACTION_TARGET])] =
            properties.target;

            // TODO: import from office-start/telemetry-type after the package update
            params[getFlattenedFieldName([ACTION_DETAILS, 'TargetId'])] =
            properties.targetId ?? '';

            params[getFlattenedFieldName([ACTION_DETAILS, PAGE_NAME])] = properties.pageName;
            params[getFlattenedFieldName([ACTION_DETAILS, PAGE_TYPE])] = this.getPageType(properties.pageType);

            const eventName =  ['Action', properties.result, properties.target].join(FIELD_NAME_SEPARATOR);
            if (this.unAuthLoggerInitialized) {
                // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
                const fullyQualifiedName = [this.nameSpace, eventName].join('.');
                // Part C fields: Different for each event category
                //   - EventCategory
                //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
                const dataFields = this.convertToDataFields(params);

                this.sendTelemetryEvent(
                    this.unAuthOtelLogger,
                    fullyQualifiedName,
                    dataFields,
                    DataCategories.ProductServiceUsage,
                    DiagnosticLevel.RequiredServiceData
                );
            } else {
                const event: TelemetryEvent = {
                    eventName,
                    params,
                    isDiagnostic: false,
                    securityThreshold: eventSecurityLevel as unknown as OtelDataSecurityLevel
                };
                this.unAuthQueue.push(event);
            }
        }
    }

    public logError(properties: ErrorProperties, isAnonymous: boolean, securityThreshold?: DataSecurityLevel): void {
        const params: TelemetryProperties = {};
        const eventSecurityLevel = securityThreshold ?? DataSecurityLevel.Security;
        const appSecurityLevel = this.getAppSecurityLevel();
        let eventName = undefined;

        if (this.eventCategoryConfig.errorEnabled) {
            if (eventSecurityLevel > appSecurityLevel || (window.disableLogs)) {
                return;
            }

            params[EVENT_NAME] = properties.eventName;
            params[EVENT_CATEGORY] = 'Error';
            params[getFlattenedFieldName([ERROR_DETAILS, ERROR_TYPE])] =
            properties.errorType ?? '';
            params[getFlattenedFieldName([ERROR_DETAILS, AREA])] =
                properties.area ?? '';
            params[getFlattenedFieldName([ERROR_DETAILS, SUB_AREA])] =
                properties.subArea ?? '';
            params[getFlattenedFieldName([ERROR_DETAILS, ERROR_CATEGORY])] =
                properties.errorCategory ?? '';
            if (properties.occurrenceCount) {
                params[getFlattenedFieldName([ERROR_DETAILS, ERROR_COUNT])] =
                    properties.occurrenceCount;
            }

            eventName =  'Error';
            if (isAnonymous ? this.unAuthLoggerInitialized : this.authLoggerInitialized) {
                // Event.Name: Office.Taos.Hub.<eventName> Event name follows a hierarchical name matching the tenant's name
                const fullyQualifiedName = [this.nameSpace, eventName].join('.');
                // Part C fields: Different for each event category
                //   - EventCategory
                //   - <EventCategory>Details (PageViewDetails.PageName, PageViewDetails.PageType, PageViewDetails.IsIntentional)
                const dataFields = this.convertToDataFields(params);

                this.sendTelemetryEvent(
                    isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger,
                    fullyQualifiedName,
                    dataFields,
                    DataCategories.ProductServiceUsage,
                    DiagnosticLevel.RequiredServiceData
                    );
            } else {
                const event: TelemetryEvent = {
                    eventName,
                    params,
                    isDiagnostic: false,
                    securityThreshold: eventSecurityLevel as unknown as OtelDataSecurityLevel
                };
                this.authQueue.push(event);
            }
        }
    }

    // Used to log errors seen during bootstrap of react shell or angular officehome app.
    public logBootError(errorName: string, details?: string, resourceUrl?: string) {
        const props = {};
        if (resourceUrl) {
            props['ResourceUrl'] = resourceUrl;
        }
        if (details) {
            props['Details'] = filterPii(details);
        }
        this.logError({eventName: errorName}, false);
    }

    public logAppBundleError(cdn: string) {
        const resourceDetails = this.getCDNResourceDetails(cdn);
        this.logBootError('App_Bundle_Parsing_Error', JSON.stringify(resourceDetails));
    }

    private getCDNResourceDetails(cdn: string): Array<ResourceTiming> {
        const details: Array<ResourceTiming> = [];
        if (performance && performance.getEntriesByType) {
            const resources = performance.getEntriesByType('resource');
            if (resources) {
                for (let i = 0; i < resources.length; i++) {
                    const resource = resources[i] as any;

                    // Currently this check only evaluates to true on Chrome and Firefox. Other browsers don't have
                    // transferSize or decodedBodySize.
                    if ((resource.initiatorType === 'link' || resource.initiatorType === 'script') &&
                        typeof resource.transferSize !== 'undefined' && typeof resource.decodedBodySize !== 'undefined' &&
                        resource.name && resource.name.indexOf(cdn) >= 0) {

                        const filenameParts = resource.name.split('/');
                        const filename = filenameParts.length > 0 ? filenameParts[filenameParts.length - 1] : '';
                        const transferSize = resource.transferSize;  // This will be 0 if resource comes from browser cache.
                        const decodedBodySize = resource.decodedBodySize;  // Un-gzipped size of resource. Can compare with resource on server/blob storage to see if it was truncated.
                        details.push({ name: filename, tSize: transferSize, dSize: decodedBodySize });
                    }
                }
            }
        }

        return details;
    }

    private isEligible(params: TelemetryProperties): boolean {
        switch (params[EVENT_CATEGORY]) {
            case EventCategory.Action:
                return this.eventCategoryConfig.actionEnabled;
            case EventCategory.Diagnostic:
                return this.eventCategoryConfig.diagnosticEnabled;
            case EventCategory.Error:
                return this.eventCategoryConfig.errorEnabled;
            case EventCategory.Feature:
                return this.eventCategoryConfig.featureEnabled;
            case EventCategory.Impression:
                return this.eventCategoryConfig.impressionEnabled;
            case EventCategory.PageView:
                return this.eventCategoryConfig.pageViewEnabled;
            case EventCategory.Performance:
                return this.eventCategoryConfig.perfEnabled;
            case EventCategory.Request:
                return this.eventCategoryConfig.requestEnabled;
            case EventCategory.Session:
                return true;
            default:
                return false;
        }
    }

    private getAppSecurityLevel(): OtelDataSecurityLevel {
        return (typeof window.windowsDataSecuritySetting === 'number' ? window.windowsDataSecuritySetting : DataSecurityLevel.Full) as unknown as OtelDataSecurityLevel;
    }

    // Function to flush the queue
    private drainQueuedEvents(
        queue: Array<TelemetryEvent>,
        isAnonymous: boolean
        ) {
            const queuedEvents = queue.splice(0, queue.length);
            queuedEvents.forEach(event => {
                if (event.eventName) {
                    this.logEvent(
                        event.eventName,
                        event.params || {},
                        event.securityThreshold as unknown as DataSecurityLevel,
                        isAnonymous ? this.unAuthOtelLogger : this.authOtelLogger
                        );
                    }
        });
    }

    private sendTelemetryEvent(
        logger: TelemetryLogger,
        eventName: string,
        dataFields: Array<DataField>,
        dataCategories: DataCategories,
        diagnosticLevel: DiagnosticLevel
    ) {
        logger.sendTelemetryEvent({
            eventName,
            dataFields,
            eventFlags: {
                dataCategories,
                diagnosticLevel
            }
        });
    }

    private async getPartAFields(
        appName: string,
        appPlatform: string,
        appWorkload: string,
        bundleBuildDate: string,
        correlationId: string,
        deploymentEnvironment: string,
        domainOrigin: string,
        flights: string,
        isCorpNet: boolean,
        isTestTraffic: boolean,
        sessionId: string,
        serverLocation: string,
        requestOrigin: string,
        identity?: OfficeIdentity,
        appWorkloadType?: string,
    ): Promise<Array<DataField>>  {
        // version : in format yyyymmd.x
        // TODO: Figure out a way to generate the version number
        const VERSION = '0';
        const buildDate = bundleBuildDate.split(' ')[0].split('-').join('');
        const appVersion = [buildDate, VERSION].join('.');

        const requiredPartADataFields: Array<DataField> = [
            ...App.getFields({
                platform: appPlatform,
                name: appName,
                version: appVersion
            }),
            ...Session.getFields({
                id: correlationId
            }),
            makeStringDataField(
                getFlattenedFieldName([RELEASE, AUDIENCE_GROUP]),
                this.getReleaseAudienceGroup(deploymentEnvironment, isCorpNet, isTestTraffic)
            ),
            makeStringDataField(DEPLOYMENT_ENV, deploymentEnvironment)
        ];

        if (identity) {
            const puid = identity?.puid;
            let idSpace = '';
            if (puid) {
                idSpace = identity?.isMsa ? 'MsaPuid' : 'OrgIdPuid';
            }

            requiredPartADataFields.push(
                ...User.getFields({
                    primaryIdentityHash: puid,
                    primaryIdentitySpace: idSpace,
                    tenantId: identity?.tenantId,
                    tenantGroup: identity?.isMsa ? 'Consumer' : 'Commercial',
                    isAnonymous: false
                })
            );
        } else {
            requiredPartADataFields.push(
                ...User.getFields({
                    isAnonymous: true
                })
            );
        }

        // TODO: Change the hard coded
        const flightsHash = await getDigest(
            crypto.subtle,
            flights,
            '', // Salt: Salt is not needed due to flight hash is not used in any security context.
            encode
        );
        const customPartADataFields: Array<DataField> = [
            makeStringDataField(EVENT_SCHEMA_VERSION, SCHEMA_VERSION),
            makeStringDataField(REQUEST_ORIGIN, requestOrigin),
            makeStringDataField(BROWSER_SESSION_ID, sessionId),
            makeStringDataField(APP_WORKLOAD, appWorkload),
            makeStringDataField(SERVER_LOCATION, serverLocation),
            makeStringDataField(DEVICE_TYPE, getDeviceType()),
            makeStringDataField(FLIGHTS, flights),
            makeStringDataField(FLIGHTS_HASH, flightsHash),
            makeStringDataField(DOMAIN_ORIGIN, domainOrigin),
            makeStringDataField(APP_WORKLOAD_TYPE, appWorkloadType ? appWorkloadType : ''),
        ];

        const partADataFields: Array<DataField> = [
            ...requiredPartADataFields,
            ...customPartADataFields
        ];

        return partADataFields;
    }

    private getReleaseAudienceGroup(
        deploymentEnvironment: string,
        isCorpNet: boolean,
        isTestTraffic: boolean
    ): ReleaseAudienceGroup {
        if (isTestTraffic) {
            return 'Automation';
        }
        switch (deploymentEnvironment) {
            case OfficeEnvironment.Dev:
            case OfficeEnvironment.PPE:
            case OfficeEnvironment.ProdPrv:
                return 'Automation';
            case OfficeEnvironment.Prod:
            case OfficeEnvironment.BFProd:
            case OfficeEnvironment.PFProd:
            case OfficeEnvironment.TBProd:
            case OfficeEnvironment.UNProd:
            case OfficeEnvironment.USProd:
                if (isCorpNet) {
                    return 'Microsoft';
                }
                return 'Production';
            default:
                return 'Automation';
        }
    }

    private getOneDsEndpoint(
        deploymentEnvironment: string
    ): OneDSEndpoint {
        switch (deploymentEnvironment) {
            case OfficeEnvironment.PFProd:
                return OneDSEndpoint.USGOV_DOD;
            case OfficeEnvironment.TBProd:
                return OneDSEndpoint.USGOV_DOJ;
            default:
                return OneDSEndpoint.PUBLIC;
        }
    }

    private getOneDsSink(
        persistentDataFields: Array<DataField>,
        deploymentEnvironment: string
        ): OneDSSink {
        return new OneDSSink(persistentDataFields, {
            endpointUrl: this.getOneDsEndpoint(deploymentEnvironment)
        });
    }

    private convertToDataFields(
        data: TelemetryProperties
    ): Array<DataField> {
        return Object.keys(data)
            .filter(
                key =>
                    typeof data[key] === 'number' ||
                    typeof data[key] === 'boolean' ||
                    typeof data[key] === 'string'
            )
            .map(key => {
                const value = data[key];
                switch (typeof value) {
                    case 'number':
                        return makeInt64DataField(key, value);
                    case 'boolean':
                        return makeBooleanDataField(key, value);
                    case 'string':
                        return makeStringDataField(key, value);
                }
            });
    }

    private getPageType(
        userType: string
    ): UserType {
        switch (userType) {
            case 'SignoutUserView':
                return 'SignOut';
            case 'ReturningUserView':
                return 'Returning';
            case 'NewUserView':
                return 'New';
            default:
                return 'Other';
        }
    }
}

(function () {
    window.standaloneOteLogger = new StandaloneOtelLogger();
})();
